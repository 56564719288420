import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Image, Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function Download() {
  return (
    <>
      <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">Get in the Game!</h3>
              <h5 className="description">
                Our app is available on any mobile device! Download now to get started!
              </h5>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <a href="https://apps.apple.com/us/app/lace-nexus/id1484227355?ls=1" >
                <img
                  alt="..."
                  className="n-logo"
                  width={200}
                  src={require("assets/img/app-store-badge.svg").default}
                ></img>
              </a>
              &nbsp;
              <a href="https://play.google.com/store/apps/details?id=com.outerfoundry.LaceNexus" >
                <img
                  alt="..."
                  className="n-logo"
                  width={200}
                  src={require("assets/img/google-play-badge.svg").default}
                ></img>
              </a>

            </Col>
          </Row>
          <br></br>

          <Row className="text-center mt-5">
            <Col className="ml-auto mr-auto" md="8">
              <h2>Want more?</h2>
              <h5 className="description">
                We are getting ready to launch:
                <br></br><br></br>
                LACE NEXUS VERSION 2!
                <br></br><br></br>
                Coming 4th Quarter 2022!
              </h5>
            </Col>
            <Col md="12">
              <Button
                className="btn-neutral btn-round"
                color="default"
                to="/pro-page"
                size="lg"
                tag={Link}
                id="upgrade-to-pro"
              >
                <i className="now-ui-icons arrows-1_share-66 mr-1"></i>
                Upgrade to PRO
              </Button>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3>Thank you for supporting us!</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-neutral btn-icon btn-round"
                color="twitter"
                href="https://twitter.com/LaceNexus"
                id="tooltip86114138"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-twitter"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip86114138">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="facebook"
                href="https://www.facebook.com/LaceNexus"
                id="tooltip735272548"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip735272548">
                Like us
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="youtube"
                href="https://www.youtube.com/channel/UCA6u0iLAMYJaD_BlU6EWUgA/"
                id="tooltip647117716"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-youtube-square"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip647117716">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="instagram"
                href="https://instagram.com/lacenexus"
                id="instagram-tooltip"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              {/* <UncontrolledTooltip delay={1} target="#instagram-tooltip">
                Like us!
              </UncontrolledTooltip> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Download;
