import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function ProBody() {
    return (
        <>
            <div className="section">
                <Container>
                    <h3 className="title">So, What's a Pro User???</h3>
                    <div id="typography">
                        <Row>
                            <Col md="12">
                                <div className="typography">
                                    <h3>
                                        It's all about having a respectful and accountable community. The internet can be an ugly place. Tired of bot's and hangry people? We are too.
                                        In order to combat this issue, if you want to post images and content to the Lace Nexus community, we require a paid subscription.
                                        And yes, we have to keep the lights on too. But mainly we want to discourage hangry people.
                                    </h3>
                                </div>
                                <div className="typography">
                                    <h2>The Cost of Doing Business</h2>
                                    <h4>
                                        <ul>
                                            <li>
                                                Unlike the big box brands, Lace Nexus is supported by our community. We don't mine your data and sell it to the higher bidder. We like you!
                                            </li>
                                            <li>
                                                The servers literally charge us for every bit of data our community uses. Pro's keep us running!
                                            </li>
                                        </ul>
                                    </h4>
                                </div>

                                <div><h2>The ability to purchase Pro is coming soon. We are working hard for you!</h2></div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default ProBody;
