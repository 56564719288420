import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function PrivacyPolicyBody() {
    return (
        <>
            <div className="section">
                <Container>
                    <h3 className="title">Updated: August 23, 2022</h3>
                    <div id="typography">
                        <Row>
                            <Col md="12">
                                <div className="typography">
                                    <h3>
                                        This privacy policy sets out how we use any information that
                                        you provide on Lace Nexus. We are committed to ensuring that
                                        your privacy is protected on all of our platforms. Should we
                                        ask you to provide certain information by which you can be
                                        identified when using this website or mobile device, then
                                        you can be assured that it will only be used in accordance
                                        with this Privacy Policy and our Terms Of Service.
                                    </h3>
                                </div>
                                <div className="typography">
                                    <h2>We May Collect</h2>
                                    <h4>
                                        <ul>
                                            <li>
                                                Your account information: Will allow us to provide you
                                                with features and services you choose. You have control over what you allow to be seen by setting your Privacy in Settings.
                                            </li>
                                            <li>
                                                Your device information: As with all mobile or web
                                                applications, you are personally identifiable so that we
                                                may provide you services, and review technology
                                                performance and problems.
                                            </li>
                                        </ul>
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>How We Use Your Information</h2>
                                    <h4>
                                        <ul>
                                            <li>
                                                For Notifications: In the future, if you choose, we will use your
                                                information to send you notifications on events you want
                                                to be notified about.
                                            </li>
                                            <li>
                                                In Search Results: We use your selections to return content that may be of interest to you.
                                            </li>
                                            <li>
                                                Shared Interests: We may match you with others or events
                                                that share your interests.
                                            </li>
                                            <li>
                                                Location and Mapping: We may use your device location to
                                                provide directions and mapping functions to you by sharing your information with Apple or Google maps.
                                            </li>
                                            <li>
                                                To Offer Services: We may offer you services, job
                                                matching, or other activities based on your profile
                                                information.
                                            </li>
                                            <li>
                                                Account Support: We use your account information, and
                                                any device collected information to provide account
                                                support.
                                            </li>
                                            <li>
                                                Game Match Making: If you are using the game matching
                                                feature, we will use your selections to match those who
                                                match your interests.
                                            </li>
                                            <li>
                                                To Enforce our Terms of Service: We have the right to
                                                limit or terminate account use for violating our Terms
                                                of Service.
                                            </li>
                                        </ul>
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>On Cookies...</h2>
                                    <h4>
                                        We use cookies to identify which pages are being used. This
                                        helps us analyze data about web page traffic and improve our
                                        website to tailor it to our customer needs. We only use this
                                        information for statistical analysis purposes and then the
                                        data is removed from the system. You can choose to accept or
                                        decline cookies. Most web browsers automatically accept
                                        cookies, but you can usually modify your browser setting to
                                        decline cookies if you prefer. This may prevent you from
                                        taking full advantage of the website.
                                        <br></br>
                                        <h5 className='policyHead'>Links to Other Sites</h5>
                                        Our website may contain links to enable you to visit other
                                        websites of interest. However, once you have used these
                                        links to leave our site, you should note that we do not have
                                        any control over that other website. Therefore, we cannot be
                                        responsible for the protection and privacy of any
                                        information which you provide whilst visiting such sites and
                                        such sites are not governed by this privacy statement. You
                                        should exercise caution and look at the privacy statement
                                        applicable to the website in question.
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default PrivacyPolicyBody;
