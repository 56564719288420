import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function DataBody() {
    return (
        <>
            <div className="section">
                <Container>
                    <h3 className="title">Updated: August 23, 2022</h3>
                    <div id="typography">
                        <Row>
                            <Col md="12">
                                <div className="typography">
                                    <h3>
                                        To provide these services, we must collect and use your
                                        personal data, but only for use within the LACE Nexus
                                        application, or for use with our 3rd party providers that you
                                        may choose to use (Maps, Purchases, etc).
                                    </h3>
                                </div>
                                <div className="typography">
                                    <h2>Your Data</h2>
                                    <h4>
                                        We value your privacy and your data. All data we collect is
                                        used to serve your needs on the LACE Nexus network.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Subscription Cancellation Policy</h2>
                                    <h4>
                                        <ul>
                                            <li>
                                                You may choose to support Lace Nexus through a
                                                subscription to one of our services.
                                            </li>
                                            <li>
                                                You may cancel any service or subscription at any time.
                                                Any previously paid subscriptions will be good until the
                                                end of the subscription period.
                                            </li>
                                            <li>
                                                We do not collect or store any of your personal payment
                                                data. We only receive and store an authorization of
                                                payment that has no connection to your payment account
                                                information.
                                            </li>
                                        </ul>
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>We May Collect</h2>
                                    <h4>
                                        <ul>
                                            <li>
                                                Your account information: Will allow us to provide you
                                                with services you choose. This will not be shared
                                                outside of our organization.
                                            </li>
                                            <li>
                                                Your device information: As with all mobile or web
                                                applications, you are personally identifiable so that we
                                                may provide you services, and review technology
                                                performance and problems. This will not be shared
                                                outside of our organization.
                                            </li>
                                        </ul>
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Your Address or Location</h2>
                                    <h4>
                                        Your address, postal code or location is used to set your
                                        "Home" area for showing local events, Service Providers and
                                        Businesses.
                                        <br />
                                        You may choose to show or hide any or all of your location
                                        to other users of LACE Nexus.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>3rd Party Data</h2>
                                    <h4>
                                        In some cases, we may use 3rd party data, for example: using Apple or Google for mapping and directions, or a game API
                                        to let you find and like games you enjoy. We do not own the content of these 3rd parties, and Lace Nexus cannot control
                                        the data they may present.
                                        When finding a location for an Event or other LACE Nexus activity, your location is made available to any mapping service you choose to use.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>3Data mining, ad targeting</h2>
                                    <h4>
                                        We do not share, resell or otherwise use your data outside of LACE Nexus, except as noted above.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Account Deletion</h2>
                                    <h4>
                                        Should you choose to be removed from our platform, you may
                                        do so under settings, "Remove Account". Note: We will remove
                                        all of your data from our system including preferences,
                                        events, friends lists. All references to you or your data
                                        will be removed from our system. This is NOT recoverable
                                        data.
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default DataBody;
