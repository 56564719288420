import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
import PrivacyHeader from "components/Headers/PrivacyHeader.js";
import PrivacyPolicyBody from "./index-sections/PrivacyPolicyBody.js";


function PrivacyPage() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <IndexNavbar />
            <div className="wrapper">
                <PrivacyHeader />
                <div className="main">
                    <PrivacyPolicyBody />
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default PrivacyPage;
