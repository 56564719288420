import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function TermsBody() {
    return (
        <>
            <div className="section">
                <Container>
                    <h3 className="title">Updated: August 23, 2022</h3>
                    <div id="typography">
                        <Row>
                            <Col md="12">
                                <div className="typography">
                                    <h3>
                                        These Terms govern your use of LACE Nexus and the products,
                                        features, apps, services, technologies, and software we
                                        offer, except where we expressly state that separate terms
                                        (and not these) apply.
                                    </h3>
                                </div>
                                <div className="typography">
                                    <h2>Our Services</h2>
                                    <h4>
                                        Our mission is to give adults with autism and the people,
                                        organizations and employers the power to build network in
                                        the local community to build a better future for all. To
                                        help advance this mission, we provide the services described
                                        below to you:
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Provide a personalized experience for you</h2>
                                    <h4>
                                        Your experience on LACE Nexus is customized to fit your
                                        needs from the posts, stories, events, ads, and other
                                        content you see. We use the data we have - for example,
                                        about the connections you make, the choices and settings you
                                        select, and any other activities on Lace Nexus in order to personalize your experience.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Connect you with people and organizations you care about</h2>
                                    <h4>
                                        LACE Nexus helps you find and connect with people, groups,
                                        businesses, organizations, and others that matter to you
                                        across the LACE Nexus services you use. We use the data we
                                        have to make suggestions for you and others - for example,
                                        groups to join, events to attend, or employment postings in
                                        your local area. Stronger networks make for better
                                        communities, and we believe our services are most useful
                                        when people are connected to people, groups, and
                                        organizations they care about.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Help you discover content, products, and services that may interest you</h2>
                                    <h4>
                                        We may show products, and services that are offered by the
                                        many businesses and organizations that use LACE Nexus and
                                        other LACE Nexus services. Some partners may pay us to show
                                        their content to you, and we try to keep the content you see
                                        as relevant and useful to you as we can.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Combat harmful conduct and protect and support our community</h2>
                                    <h4>
                                        People will only grow in the local community on LACE Nexus
                                        if they feel safe. We intend to prevent harmful conduct
                                        towards others. If we learn of content or conduct like this,
                                        we will take appropriate action - for example, offering
                                        help, removing content, blocking access to certain features,
                                        disabling an account, or contacting law enforcement.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>3rd Party Data</h2>
                                    <h4>
                                        In some cases, we may use 3rd party data, for example: using Apple or Google for mapping and directions, or a game API
                                        to let you find and like games you like. We do not own the content of these 3rd parties, and Lace Nexus cannot control
                                        the data they may present.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Research ways to make our services better</h2>
                                    <h4>
                                        We engage in research to improve our services. One way we do
                                        this is by analyzing the data we have and understanding how
                                        people use our services.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Provide consistent and seamless experiences across the LACE Nexus Sites and Apps</h2>
                                    <h4>
                                        Our Products help you find and connect with people, groups,
                                        businesses, organizations, and others that are important to
                                        you. We design our systems so that your experience is
                                        consistent and seamless across the different LACE Nexus
                                        services that you use.
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Community Commitments</h2>
                                    <h4>
                                        We provide these services to you and others to help advance
                                        our mission. In exchange, we need you to make the following
                                        commitments:
                                    </h4>
                                </div>

                                <div className="typography">
                                    <h2>Community Commitments</h2>
                                    <h3>
                                        1. Who can use LACE Nexus
                                    </h3>
                                    <h4>When people stand behind their opinions and actions, our
                                        community is safer and more accountable.
                                    </h4>
                                    {/* <h4> */}
                                    <p>For that reason, you must:</p>
                                    <ul>
                                        <li>Use the same name that you use in everyday life.</li>
                                        <li>Provide accurate information about yourself.</li>
                                        <li>
                                            Create only one account (your own) and use for personal
                                            purposes. If you are a Business, you may also create a personal account.
                                        </li>
                                        <li>
                                            Respect yourself and others by refraining from using
                                            profanity or other objectionable content on the NEXUS.
                                        </li>
                                        <li>
                                            Not share your password, give access to your LACE Nexus
                                            account to others, or transfer your account to anyone
                                            else (without our permission).
                                        </li>
                                    </ul>
                                    <p>
                                        We try to make LACE Nexus broadly available to everyone,
                                        but you cannot use LACE Nexus if:
                                    </p>
                                    <ul>
                                        <li>You are under 18 years old.</li>
                                        <li>You are a convicted sex offender.</li>
                                        <li>
                                            We previously disabled your account for violations of
                                            our terms or policies.
                                        </li>
                                        <li>
                                            You are prohibited from receiving our products,
                                            services, or software under applicable laws.
                                        </li>
                                    </ul>
                                    <h3>
                                        2. What you can share and do on LACE Nexus
                                    </h3>
                                    We want people to use LACE Nexus to express themselves and
                                    to share content that is important to them, but not at the
                                    expense of the safety and well-being of others or the
                                    integrity of our community. You therefore agree not to
                                    engage in the conduct described below (or to facilitate or
                                    support others in doing so):
                                    <p>
                                        1. You may not use our Products to do or share anything:
                                    </p>
                                    <ul>
                                        <li>
                                            That violates these Terms, our Community Standards, and
                                            other terms and policies that apply to your use of LACE
                                            Nexus.
                                        </li>
                                        <li>
                                            That is unlawful, misleading, discriminatory or
                                            fraudulent.
                                        </li>
                                        <li>That infringes or violates someone else's rights.</li>
                                    </ul>
                                    <p>
                                        2. You may not upload viruses or malicious code or do
                                        anything that could disable, overburden, or impair the
                                        proper working or appearance of our Products.
                                    </p>
                                    <p>
                                        3. You may not access or collect data from our Products
                                        using automated means (without our prior permission) or
                                        attempt to access data you do not have permission to
                                        access.
                                        <br />
                                        We can remove content you share in violation of these
                                        provisions and, if applicable, we may take action against
                                        your account, for the reasons described below. We may also
                                        disable your account if you repeatedly infringe other
                                        people's intellectual property rights.
                                        <br />
                                        To help support our community, we encourage you to report
                                        content or conduct that you believe violates your rights
                                        (including intellectual property rights) or our terms and
                                        policies.
                                    </p>
                                    <h3>
                                        3. The permissions you give us
                                    </h3>
                                    We need certain permissions from you to provide our
                                    services:
                                    <p>
                                        1. Permission to use content you create and share: You own
                                        the content you create and share on LACE Nexus and the
                                        other LACE Nexus Products you use, and nothing in these
                                        Terms takes away the rights you have to your own content.
                                        You are free to share your content with anyone else,
                                        wherever you want. To provide our services, though, we
                                        need you to give us some legal permissions to use that
                                        content. Specifically, when you share, post, or upload
                                        content that is covered by intellectual property rights
                                        (like photos or videos) on or in connection with our
                                        Products, you grant us a non-exclusive, transferable,
                                        sub-licensable, royalty-free, and worldwide license to
                                        host, use, distribute, modify, run, copy, publicly perform
                                        or display, translate, and create derivative works of your
                                        content (consistent with your privacy and application
                                        settings). This means, for example, that if you share a
                                        photo on LACE Nexus, you give us permission to store,
                                        copy, and share it with others (again, consistent with
                                        your settings) such as service providers that support our
                                        service or other LACE Nexus Products you use. You can end
                                        this license any time by deleting your content or account.
                                        You should know that, for technical reasons, content you
                                        delete may persist for a limited period of time in backup
                                        copies (though it will not be visible to other users). In
                                        addition, content you delete may continue to appear if you
                                        have shared it with others and they have not deleted it.
                                    </p>
                                    <p>
                                        2. Permission to use your name, profile picture, and
                                        information about your actions. You give us permission to
                                        use your name and profile picture and information about
                                        actions you have taken on LACE Nexus next to or in
                                        connection with content that we display across our
                                        Products, without any compensation to you. For example, we
                                        may show your friends that you are interested in an
                                        advertised event or have liked a Page created by a brand
                                        that has paid us to display on LACE Nexus.
                                    </p>
                                    <p>
                                        3. Permission to update software you use or download: If
                                        you download or use our software, you give us permission
                                        to download and install upgrades, updates, and additional
                                        features to improve, enhance, and further develop it.
                                    </p>
                                    <p>
                                        4. Limits on using our intellectual property: If you use
                                        content covered by intellectual property rights that we
                                        have and make available in our Products (for example,
                                        images, designs, videos, or sounds we provide that you add
                                        to content you create or share on LACE Nexus), we retain
                                        all rights to that content (but not yours). You can only
                                        use our copyrights or trademarks (or any similar marks) as
                                        expressly permitted by our Brand Usage Guidelines or with
                                        our prior written permission. You must obtain our written
                                        permission (or permission under an open source license) to
                                        modify, create derivative works of, decompile, or
                                        otherwise attempt to extract source code from us.
                                    </p>
                                    {/* </h4> */}
                                </div>

                                <div className="typography">
                                    <h2>Additional Provisions</h2>
                                    <div className='policyHead'>1. Updating our Terms:</div>
                                    We work constantly to improve our services and develop new
                                    features to make our Products better for you and our
                                    community. As a result, we may need to update these Terms
                                    from time to time to accurately reflect our services and
                                    practices. Unless otherwise required by law, we will notify
                                    you before we make changes to these Terms and give you an
                                    opportunity to review them before they go into effect. Once
                                    any updated Terms are in effect, you will be bound by them
                                    if you continue to use our Products.
                                    <br />
                                    We hope that you will continue using our Products, but if
                                    you do not agree to our updated Terms and no longer want to
                                    be a part of the LACE Nexus community, you can delete your
                                    account at any time.
                                    <div className='policyHead'>
                                        2. Account suspension or termination:
                                    </div>
                                    We want LACE Nexus to be a place where people feel welcome
                                    and safe to find events and services appropriate to you.
                                    <br />
                                    If we determine that you have violated our terms or
                                    policies, we may take action against your account to protect
                                    our community and services, including by suspending access
                                    to your account or disabling it. We may also suspend or
                                    disable your account if you create risk or legal exposure
                                    for us or when we are permitted or required to do so by law.
                                    Where appropriate, we will notify you about your account the
                                    next time you try to access it. You can learn more about
                                    what you can do if your account has been disabled.
                                    <br />
                                    If you delete your account, these Terms shall terminate as
                                    an agreement between you and us, but the following
                                    provisions will remain in place: 3, 4.
                                    <div className='policyHead'>3. Limits on liability:</div>
                                    We work hard to provide the best Products we can and to
                                    specify clear guidelines for everyone who uses them. Our
                                    Products, however, are provided "as is," and we make no
                                    guarantees that they always will be safe, secure, or
                                    error-free, or that they will function without disruptions,
                                    delays, or imperfections. To the extent permitted by law, we
                                    also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
                                    INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                                    FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. We do
                                    not control or direct what people and others do or say, and
                                    we are not responsible for their actions or conduct (whether
                                    online or offline) or any content they share (including
                                    offensive, inappropriate, obscene, unlawful, and other
                                    objectionable content).
                                    <br />
                                    We cannot predict when issues might arise with our Products.
                                    Accordingly, our liability shall be limited to the fullest
                                    extent permitted by applicable law, and under no
                                    circumstance will we be liable to you for any lost profits,
                                    revenues, information, or data, or consequential, special,
                                    indirect, exemplary, punitive, or incidental damages arising
                                    out of or related to these Terms or the LACE Nexus Products,
                                    even if we have been advised of the possibility of such
                                    damages. Our aggregate liability arising out of or relating
                                    to these Terms or the LACE Nexus Products will not exceed
                                    the greater of $100 or the amount you have paid us in the
                                    past twelve months.
                                    <div className='policyHead'>4. Disputes:</div>
                                    We try to provide clear rules so that we can limit or
                                    hopefully avoid disputes between you and us. If a dispute
                                    does arise, however, it's useful to know up front where it
                                    can be resolved and what laws will apply.
                                    <br />
                                    For any claim, cause of action, or dispute you have against
                                    us that arises out of or relates to these Terms or the LACE
                                    Nexus Products ("claim"), you agree that it will be resolved
                                    exclusively in the U.S. District Court - Texas. You also
                                    agree to submit to the personal jurisdiction of this court
                                    for the purpose of litigating any such claim, and that the
                                    laws of the State of Texas will govern these Terms and
                                    any claim, without regard to conflict of law provisions.
                                    <div className='policyHead'>5. Other:</div>
                                    <ul>
                                        <li>
                                            These Terms make up the entire agreement between you and
                                            LACE Nexus, regarding your use of our Products.
                                            They supersede any prior agreements.
                                        </li>
                                        <li>
                                            Some of the Products we offer are also governed by
                                            supplemental terms. If you use any of those Products,
                                            supplemental terms will be made available and will
                                            become part of our agreement with you. For instance, if
                                            you access or use our Products for commercial or
                                            business purposes, such as selling products, developing
                                            apps, managing a group or Page for your business, or
                                            using our measurement services, you must agree to our
                                            Commercial Terms. To the extent any supplemental terms
                                            conflict with these Terms, the supplemental terms shall
                                            govern to the extent of the conflict.
                                        </li>
                                        <li>
                                            If any portion of these Terms are found to be
                                            unenforceable, the remaining portion will remain in full
                                            force and effect. If we fail to enforce any of these
                                            Terms, it will not be considered a waiver. Any amendment
                                            to or waiver of these Terms must be made in writing and
                                            signed by us.
                                        </li>
                                        <li>
                                            You will not transfer any of your rights or obligations
                                            under these Terms to anyone else without our consent.
                                        </li>
                                        <li>
                                            You may designate a person (called a legacy contact) to
                                            manage your account if it is memorialized. Only your
                                            legacy contact or a person who you have identified in a
                                            valid will or similar document expressing clear consent
                                            to disclose your content upon death or incapacity will
                                            be able to seek disclosure from your account after it is
                                            memorialized.
                                        </li>
                                        <li>
                                            These Terms do not confer any third-party beneficiary
                                            rights. All of our rights and obligations under these
                                            Terms are freely assignable by us in connection with a
                                            merger, acquisition, or sale of assets, or by operation
                                            of law or otherwise.
                                        </li>
                                        <li>
                                            You should know that we may need to change the username
                                            for your account in certain circumstances (for example,
                                            if someone else claims the username and it appears
                                            unrelated to the name you use in everyday life).
                                        </li>
                                        <li>
                                            We always appreciate your feedback and other suggestions
                                            about our products and services. But you should know
                                            that we may use them without any restriction or
                                            obligation to compensate you, and we are under no
                                            obligation to keep them confidential.
                                        </li>
                                        <li>
                                            We reserve all rights not expressly granted to you.
                                        </li>
                                    </ul>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default TermsBody;
